import { Suspense, useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import useApi from "../hooks/useApi";

export function Component() {
  const api = useApi();

  const [isLoading, setIsLoading] = useState(true);
  const [winners, setWinners] = useState<any[]>([]);

  const handleSuccess = (response: any) => {
    setIsLoading(false);
    setWinners(response?.data ?? []);
  };

  // const handleError = () => {
  //   setIsLoading(false);
  //   setWinners([]);
  // };

  useEffect(() => {
    // api.getWinners().then(handleSuccess).catch(handleError);

    handleSuccess({
      data: [
        {
          nome: "Sergio XXXX da Silveira",
          cpf: "029.XXX.XXX-34",
          cidade: "São Paulo - SP",
          numero: "01 73877",
        },
      ],
    });
  }, [api]);

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container>
          <h1 className="text-center mb-5">GANHADOR</h1>

          {isLoading && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "50vh" }}
            >
              <p className="fs-3 mt-5 text-center">Carregando...</p>
            </div>
          )}

          {!isLoading &&
            (winners.length !== 0 ? (
              <Row>
                <Col xs={12} lg={6} className="p-0 pe-lg-2 mx-auto">
                  <Table size="sm" striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Nome</th>
                        <th>CPF</th>
                        <th style={{ textAlign: "center" }}>Número</th>
                        <th style={{ textAlign: "center" }}>Cidade/UF</th>
                      </tr>
                    </thead>
                    <tbody>
                      {winners.slice(0, 25).map((winner, index) => (
                        <tr key={index}>
                          <td width={400}>{winner.nome}</td>
                          <td width={200}>{winner.cpf}</td>
                          <td width={100} align="center">
                            {winner.numero}
                          </td>
                          <td width={100} align="center">
                            {winner.cidade}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : (
              <div
                className="d-flex align-items-center justify-content-center"
                style={{ height: "50vh" }}
              >
                <p className="fs-3 mt-5 text-center">
                  Aguarde a divulgação do ganhador.
                </p>
              </div>
            ))}
        </Container>
      </section>
    </Suspense>
  );
}
